import React, { useState, useRef, useEffect } from "react";
import Sticky from "react-stickynode";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { themeGet } from "styled-system";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
// import Reaptcha from 'reaptcha';

import { saasTheme } from "common/src/theme/saas";
import { ResetCSS } from "common/src/assets/css/style";
import { DrawerProvider } from "common/src/contexts/DrawerContext";
import Heading from "common/src/components/Heading";
import Text from "common/src/components/Text";
import Input from "common/src/components/Input";
import Button from "common/src/components/Button";

import { GlobalStyle } from "../containers/Saas/saas.style";
import SEO from "../components/seo";
import Navbar from "../containers/Saas/Navbar";
import useForm from "../components/useForm";
import navBg from "common/src/assets/image/saas/nav-bg.png";

const GlobalStyle1 = createGlobalStyle`
.g-recaptcha > div {
  margin: 0 auto 15px !important;
 }
 .fullWidth {
  width: 100%;
}
`;
const MainWrapper = styled.main`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

// This is different from the stock. It doesn't render search results right.
// No time to waste with this right now.
const ContentWrapper = styled.div`
  position: relative;
  a:-webkit-any-link {
    text-decoration: none;
  }
  .sticky-nav-active {
    .hosting_navbar {
      background: #fff;
      box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
      padding: 10px 0;
    }
  }

  .hosting_navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    transition: 0.35s ease-in-out;
    padding: 15px 10px;
    .main_menu {
      margin-right: 40px;
      margin-bottom: 0px;
      li {
        display: inline-block;
        padding-left: 13px;
        padding-right: 13px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
        &.is-current {
          background: transparent url(${navBg}) no-repeat center bottom;
          a {
            color: ${themeGet("colors.primary", "#008abe")};
          }
        }
        a {
          padding: 5px;
          font-size: 18px;
          font-weight: 400;
          color: #343d48;
          transition: 0.15s ease-in-out;
          &:hover {
            color: ${themeGet("colors.primary", "#008abe")};
          }
        }
      }
      @media (max-width: 990px) {
        display: none;
      }
    }
    .navbar_button {
      @media (max-width: 990px) {
        display: none;
      }
    }
    .reusecore-drawer__handler {
      margin-left: 15px;
      // position: fixed;
      // right: 20px;
      @media (min-width: 991px) {
        display: none !important;
      }
      .hamburgMenu__bar {
        > span {
        }
      }
    }
  }

  .trial-section {
    background: linear-gradient(to bottom, #fafbff 0%, #f7f8fd 100%);

    .button_group {
      text-align: center;
    }
  }

  @media (max-width: 990px) {
    .glide__slide--active .pricing_table {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
      border: 0;
    }
  }
`;

const FormWrapper = styled.div`
  width: 525px;
  margin: 130px auto;
  @media (max-width: 780px) {
    margin: 80px auto;
  }
  @media (max-width: 1050px) {
    padding: 0 10px 0;
  }

  .reusecore__input {
    /* margin-bottom: 30px; */
    &.is-material {
      &.is-focus {
        label {
          color: ${themeGet("colors.primary", "#10ac84")};
          top: -12px;
        }
        .highlight {
          background-color: ${themeGet("colors.primary", "#10ac84")};
        }
      }
    }

    label {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      top: 15px;
    }
  }

  .reusecore__button {
    background-color: transparent;
    &.default {
      background-color: ${themeGet("colors.primary", "#10ac84")};
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
      }
    }
  }

  .input_error {
    height: 40px;
    color: red;
    display: block;
  }

  .confirm_disable {
    pointer-events: none;
    opacity: 0.5;
  }
`;

const Error = ({ value, email }) => (
  <>
    <h2 style={{ color: "red" }}>Error!</h2>
    <p>
      We appologize but following error occured during your registration:
      <h3 style={{ color: "black" }}>{value}</h3>
      We have been notified about this issue, and will inform you about
      registration status at <b style={{ color: "black" }}>{email}</b>.
      <br />
      <br />
      Do not hesitate to <a href="/contact-us">Contact Us</a>.
    </p>
  </>
);

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;
const Success = ({ email }) => (
  <>
    <h2 style={{ color: "green" }}>Thank you</h2>
    <p>
      Your message has been delivered to Us.
      <br />
      Expect reply on your e-mail at <b style={{ color: "black" }}>{email}</b>.
    </p>
    <ButtonWrapper>
      <Button
        onClick={() => {
          window.history.back();
        }}
        className="default"
        title="Go to previous page"
      />
    </ButtonWrapper>
  </>
);

export default () => {
  const Data = useStaticQuery(graphql`
    query {
      BackgroundImage: file(
        relativePath: { eq: "image/saas/saas-banner.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      BannerObject2: file(
        relativePath: { eq: "image/saas/screenshots/workplan.png" }
      ) {
        childImageSharp {
          original {
            width
            height
            src
          }
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <ThemeProvider theme={saasTheme}>
      <BackgroundImage
        Tag="section"
        style={{
          backgroundAttachment: "fixed",
          height: "100vh",
        }}
        fluid={Data.BackgroundImage.childImageSharp.fluid}
      >
        <SEO
          title="RepairSetup - Contact Us"
          keywords={[
            "technician",
            "repair",
            "appliance",
            "plumber",
            "handyman",
            "ORHP",
            "CCHS",
            "HSA",
            "AHS",
            "field",
            "software",
          ]}
          description="Contact Us - RepairSetup"
          image={Data.BannerObject2.childImageSharp.original.src}
          alt="RepairSetup Banner image"
          imageWidth={Data.BannerObject2.childImageSharp.original.width}
          imageHeight={Data.BannerObject2.childImageSharp.original.height}
        />
        <ResetCSS />
        <GlobalStyle />
        <GlobalStyle1 />
        <ContentWrapper>
          <Sticky top={0} innerZ={1000} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <MainWrapper>
            <FormWrapper>
              <Form />
            </FormWrapper>
          </MainWrapper>
        </ContentWrapper>
      </BackgroundImage>
    </ThemeProvider>
  );
};

const nameStyle = {
  fontSize: ["28px", "28px", "30px", "30px", "30px"],
  fontWeight: "600",
  color: "headingColor",
  letterSpacing: "-0.025em",
  textAlign: "center",
  mb: "32px",
};

const descriptionStyle = {
  fontSize: ["15px", "16px", "16px", "16px", "16px"],
  color: "textColor",
  lineHeight: "1.75",
  textAlign: "center",
  mb: "32px",
};

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Form = ({}) => {
  const validator = (values) => {
    const errors = {};

    if (!values.message) {
      errors.message = "Required";
    }

    if (!values.email) {
      errors.email = "Required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = "Email address is invalid";
    }

    return errors;
  };
  const { values, errors, handleChange } = useForm(null, validator);
  const [state, setState] = useState();
  const captcha = useRef();
  const inputEmail = useRef();
  // const [captchaToken, setCaptchaToken] = useState()
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    inputEmail.current && inputEmail.current.focus();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formValues = { ...values };
    // if (captchaToken) {
    //   formValues["g-recaptcha-response"] = captchaToken
    // }

    setSubmitting(true);
    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "contact", ...formValues }),
      });
      setState({ name: "success" });
    } catch (err) {
      console.dir(err);
      setState({ name: "error", error: err });
    }
    setSubmitting(false);
  };
  const handleInputChange = (name) => (value) => {
    // if (captchaToken) {
    //   captcha.current.reset()
    //   setCaptchaToken(null)
    // }
    handleChange(name, value);
  };
  // const onCaptchaVerify = token => {
  //   setCaptchaToken(token)
  // }
  // const onCaptchaError = err => {
  //   captcha.current.reset()
  //   setCaptchaToken(null)
  // }
  // const onCaptchaExpire = () => {
  //   captcha.current.reset()
  //   setCaptchaToken(null)
  // }

  return (
    <>
      {state?.name === "error" && (
        <Error value={state.error} email={values.email} />
      )}
      {state?.name === "success" && <Success email={values.email} />}
      {!state && (
        <>
          <Heading as="h1" content="Contact Us" {...nameStyle} mb="10px" />
          <Text
            content="Send us your message, and we will take care for the rest."
            {...descriptionStyle}
          />
          <form
            name="contact"
            method="post"
            action="/thanks/"
            onSubmit={handleSubmit}
            data-netlify="true"
            // data-netlify-recaptcha="true"
          >
            <input type="hidden" name="form-name" value="contact" />
            <Input
              ref={inputEmail}
              inputType="email"
              label="Email"
              onChange={handleInputChange("email")}
              tabIndex="1"
            />
            <small className="input_error">{errors.email}</small>

            <Input
              inputType="textarea"
              rows={3}
              label="Message"
              onChange={handleInputChange("message")}
              tabIndex="2"
            />
            <small className="input_error">{errors.message}</small>

            {/* <Reaptcha
          ref={captcha}
          sitekey={process.env.RECAPTCHA_SITE_API_KEY}
          onVerify={onCaptchaVerify}
          onError={onCaptchaError}
          onExpire={onCaptchaExpire}
        /> */}

            {/* <div className={(Object.keys(errors).length > 0 || !captchaToken) ? "confirm_disable" : null}> */}
            <Button
              type="submit"
              className="default fullWidth"
              title="SEND"
              disabled={submitting || Object.keys(errors).length > 0}
            />
            {/* </div> */}
          </form>
        </>
      )}
    </>
  );
};
