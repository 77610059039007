import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import urljoin from 'url-join';

function SEO({ description, lang, meta, keywords, title, image, alt, imageWidth, imageHeight }) {
  return (
    <StaticQuery
      query={detailsQuery}
      render={data => {
        const metaDescription =
          description || data.site.siteMetadata.description;
        const metaImage = image && urljoin(data.site.siteMetadata.siteUrl, image);
        const m = [
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:title`,
            content: title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ]
          .concat(
            keywords.length > 0
              ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
              : []
          )
          .concat(meta)

        if (metaImage) {
          m.push({ name: "og:image", content: metaImage })
          m.push({ name: "og:image:secure_url", content: metaImage })
          m.push({ name: "twitter:image:src", content: metaImage })
          m.push({ name: "twitter:image", content: metaImage })
        }
        if (alt) {
          m.push({ name: "og:image:alt", content: alt })
          m.push({ name: "twitter:image:alt", content: alt })
        }
        if (imageWidth && imageHeight) {
          m.push({ name: "og:image:width", content: imageWidth })
          m.push({ name: "og:image:height", content: imageHeight })
          m.push({ name: "twitter:image:width", content: imageWidth })
          m.push({ name: "twitter:image:height", content: imageHeight })
        }
        return (
          <Helmet
            htmlAttributes={{
              lang,
            }}
            title={title}
            titleTemplate={`%s ${data.site.siteMetadata.title}`}
            meta={m}
          />
        );
      }}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
  }
`;
